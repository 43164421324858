import { Plugin } from '@nuxt/types'
import Cookies from 'universal-cookie'

let cookies: Cookies | null = null

const cookiesPlugin: Plugin = ({
  $config: {
    NUXT_ENV_BASE_URL
  }
}, inject) => {
  const cookieOption = {
    path: '/',
    domain: NUXT_ENV_BASE_URL.replace(/https?:\/\/(www|local)\./g, '')
  }

  cookies = cookies || new Cookies(cookies, cookieOption)

  const cookiesSnapshot = cookies.getAll()
  
  for (const cookieName in cookiesSnapshot) {
    if (Object.hasOwn(cookiesSnapshot, cookieName)) {
      const cookieValue = cookiesSnapshot[cookieName];
      if (
        typeof cookieValue === 'string'
        && (cookieValue === 'undefined'
        || cookieValue === '')
      ) {
        cookies.remove(cookieName, cookieOption)
      }
    }
  }

  inject('cookies', cookies)
}

export default cookiesPlugin