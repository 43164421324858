import tiers from '@/locales/tiers/index.fr'
import globals from '@/locales/globals/index.fr'

export default {
  tiers,
  globals,
  forms: {
    boutiquesParticipantInformations:
      'Copier les informations saisies pour tous les acheteurs suivants',
    evenementsCopyParticipantInformations:
      'Copier les informations saisies pour tous les participants suivants',
    adhesionsCopyParticipantInformations:
      'Copier les informations saisies pour tous les participants suivants',
    requiredField: 'Ce champ est obligatoire'
  },
  administration: {
    admin: 'Administrateurs',
    share: 'ma page',
    shareShort: 'Partager',
    stats: 'Statistiques',
    update: 'les contenus',
    updateShort: 'Modifier'
  },
  button: {
    choose: 'Choisir',
    add: 'Ajouter',
    back: 'Retour',
    cancel: 'Annuler',
    copy: 'Copier',
    remove: 'Retirer',
    save: 'Enregistrer',
    update: 'Modifier',
    send: 'Envoyer'
  },
  campaign: {
    currencyUnit: '{0} €',
    projectExpensesDetails: 'Description des postes de dépenses',
    projectBeneficiaries: 'Bénéficiaires finaux de la collecte',
    projectOwners: 'Porteurs du projet',
    projectTargetCountry: 'Localisation du projet',
    fundedAt: 'Financé à <b>{0}%</b> - <b>{1}€</b> / {2}€',
    goal: 'Objectif : {0} €',
    aboutOrganism: "A propos de l'association",
    contribute: 'Contribuer au projet',
    saleEndDate: 'Fin dans',
    saleStartDate: 'Début dans',
    MembershipMembers: 'Adhérent | Adhérent | Adhérents',
    CrowdFundingMembers: 'Contributeur | Contributeur | Contributeurs',
    EventMembers: 'Participant | Participant | Participants',
    amountCollected: 'collecté | collecté | collectés',
    membershipFrom: 'Adhésion valable',
    customTierPrice: 'Montant libre',
    myPersonalInformations: 'Mes coordonnées',
    donation: 'Don',
    selection: 'Sélection',
    myDonation: 'Mon don',
    mySummary: 'Mon récapitulatif',
    monthlyTotal: 'Total mensuel',
    total: 'Total',
    amountTtc: 'Montant TTC :',
    amountTva: 'Dont TVA :',
    IGiveMonthly: 'Je donne tous les mois',
    IGiveOneTime: 'Je donne une fois',
    priceAfterRfReduction: 'Votre don ne vous coûtera que <b>{0}€</b> après réduction fiscale',
    fiscalReduction: 'Réduction Fiscale',
    fiscalReductionRuban: 'Fiscalité',
    fiscalReductionDescription:
      'Le don à <b>{0}</b> ouvre droit à une réduction fiscale car il remplit les conditions générales prévues aux articles 200 et 238 bis du code général des impôts.',
    personalInformationsIsPerson: 'Particulier',
    personalInformationsIsCompany: 'Organisme',
    logoOf: "Logo de l'association {0}",
    seePaymentSchedule: 'Voir le détail',
    hidePaymentSchedule: 'Cacher le détail',
    date: {
      noDate: {
        with2Times: 'De {0} à {1}'
      },
      SameDay: {
        with2Times: 'Le {0}, de {1} à {2}',
        withEndTime: 'Le {0}, jusqu’à {1}',
        withNoTime: 'Le {0}',
        withStartTime: 'Le {0}, à partir de {1}'
      },
      with2Dates: {
        with2Times: 'Du {0} au {1}, de {2} à {3}',
        withEndTime: 'Du {0} au {1}, jusqu’à {2}',
        withNoTime: 'Du {0} au {1}',
        withStartTime: 'Du {0} au {1}, à partir de {2}'
      }
    },
    error: {
      missingInfo: 'Informations manquantes',
      emptyCart: 'Nous avons besoin que votre commande contienne au moins un article',
      fillMandatory: 'Veuillez remplir les champs obligatoires',
      fileIsUploading: "Votre fichier est en cours d'envoi",
      pleaseWaiting: 'Merci de patienter'
    },
    field: {
      yourEmailAddress: 'Votre adresse email',
      address: 'Adresse',
      birthday: 'Date de naissance',
      city: 'Ville',
      copyPayer: 'Copier les informations saisies pour tous les participants suivants',
      country: 'Pays',
      email: 'Email',
      emailConfirmation: 'Confirmation Email',
      file: 'Cliquez ici pour importer un fichier',
      firstName: 'Prénom',
      lastName: 'Nom',
      organism: 'Raison sociale',
      payer: 'Ce participant va effectuer le paiement',
      zipcode: 'Code postal',
      companySiren: 'SIREN',
      companyLegalStatusId: 'Forme juridique',
      paymentDate: {
        nextPayment:
          "Afin de valider votre carte bancaire, votre premier prélèvement sera effectué aujourd'hui. Le prochain aura lieu le ",
        paymentDay_01: 'Vous serez prélevé tous les',
        paymentDay_02: 'de chaque mois.',
        paymentDayErrorRequired: 'Veuillez saisir votre premier jour de prélèvement.',
        paymentDayErrorDay: 'Veuillez saisir un jour entre 1 et {0}.'
      }
    },
    nextStep: 'Suivant',
    previousStep: 'Précédent',
    order: 'Commander',
    report: 'Signaler la page',
    requiredFields: '* Champs obligatoires',
    requiredFieldsLowerCase: '*champs obligatoires',
    state: {
      button: 'Accéder à mes paramètres',
      deleted: {
        subtitle: 'Pour la restaurer, rendez-vous dans les paramètres de votre campagne.',
        title: 'Cette campagne a été supprimée !'
      },
      disabled: {
        subtitle: 'Pour la réactiver, rendez-vous dans les paramètres de votre campagne.',
        title: 'Cette campagne est désactivée !'
      },
      draft: {
        subtitle: 'Pour la publier, rendez-vous dans les paramètres de votre campagne.',
        title: 'Cette campagne est en brouillon !'
      }
    },
    step: {
      blockedBanner: {
        paymentHasBeenSuspended:
          'Le paiement ou commande sur cette campagne est temporairement suspendu.',
        giveUsEmailToBeNotified:
          "Indiquez-nous votre email pour être notifié de sa réouverture prochaine! L'association {0} reviendra vite vers vous."
      },
      vente: {
        noVatRate: 'Achat non-soumis à TVA',
        lastPayment:
          "Afin de valider votre carte bancaire, votre premier prélèvement sera effectué aujourd'hui. Votre dernier prélèvement aura lieu le {0}.",
        paymentDay_01: 'Vous serez prélevé tous les',
        paymentDay_02: 'de chaque mois.',
        vatRate: 'Achat soumis à TVA ({0} %)',
        amount: 'Montant (TTC) à régler',
        freeAmount: 'Indiquez le montant à payer'
      },
      participants: {
        extraOptions: 'Options supplémentaires',
        furtherInformations: 'Informations complémentaires',
        participant: 'Participant',
        participantProfile: 'Profil du participant',
        vat: 'TVA',
        type: {
          shop: {
            participant: 'Acheteur',
            participantProfile: "Profil de l'acheteur",
            action: 'Acheter'
          },
          event: {
            participant: 'Participant',
            participantProfile: 'Profil du participant',
            action: 'Participer'
          },
          membership: {
            participant: 'Adhérent',
            participantProfile: 'Coordonnées',
            action: 'Adhérer'
          },
          crowdfunding: {
            participant: 'Contributeur',
            action: 'Contribuer'
          },
          donation: {
            participant: 'Contributeur',
            action: 'Contribuer'
          },
          paymentform: {
            participant: 'Payeur',
            action: 'Payer'
          }
        }
      },
      payer: {
        existingAccount: "J'ai déjà un compte HelloAsso :",
        furtherInformations: 'Informations supplémentaires',
        login: 'je me connecte',
        logout: "Modifier l'adresse email",
        finalizeAsOrg: "Finaliser en tant qu'organisme",
        payAsOrg: "Payer en tant qu'organisme",
        title: 'Informations de paiement',
        titleFree: 'Vérifiez vos coordonnées'
      },
      summary: {
        acceptDocument: "J'accepte le document suivant :",
        acceptGdpr_1: "J'accepte les",
        acceptGdpr_2: "du service et j'ai lu la",
        acceptGdprLink_1: 'Conditions Générales d’Utilisation',
        acceptGdprLink_2: 'charte de confidentialité',
        contributingToHelloAsso: 'Votre contribution à HelloAsso',
        discount: 'Réduction',
        donationTitle: 'Don',
        donationCrowd: 'Don',
        donation: 'Don supplémentaire à {0}',
        frequency: {
          installment: 'Paiement en {0} échéances',
          monthly: 'Paiement mensuel'
        },
        product: 'Produits',
        membership: 'Adhésions',
        options: 'Options',
        option: 'Option',
        payment: 'Montant (TTC)',
        total: {
          donation: 'Don',
          options: 'Options',
          reduction: 'Réduction',
          title: 'Total',
          type: {
            crowdfunding: 'Contributions',
            event: 'Billets',
            membership: 'Adhésions',
            shop: 'Produits'
          }
        },
        priceFixed: 'Prix défini',
        priceFree: 'Prix libre',
        schedulerHelp:
          "Vos options sont à régler lors de votre première échéance. Votre réduction - si vous en avez une - s'applique sur vos échéances. La contribution volontaire que vous décidez de donner à HelloAsso est la même pour chacune des échéances.",
        titleBill: 'Récapitulatif',
        titleScheduler: 'Vos échéances',
        understandContribution:
          "J'ai compris que HelloAsso est une entreprise sociale et solidaire et confirme vouloir lui apporter une contribution financière, facultative et modifiable, en finalisant mon paiement pour assurer la gratuité du service à l'association. *",
        totalContribution: '(Soit {0} € au total)',
        byTerms: ' par échéance',
        type: {
          crowdfunding: {
            options: 'Contribution'
          },
          shop: {
            options: 'Produits et options'
          },
          event: {
            options: 'Billets et options'
          },
          membership: {
            options: 'Adhésions et options'
          },
          paymentform: {
            options: 'Montant de la vente'
          }
        },
        updateContribution: 'Modifier',
        whyContribute: 'Pourquoi ?',
        whereGoesMyContribution: 'Où va ma contribution ?'
      },
      ticketingSelect: {
        free: 'gratuit',
        freePrice: 'Prix Libre',
        from: 'À partir de {0} €',
        yourCrowdFundingItemWillCostOnly:
          'Votre don ne vous coûtera que {0}€ après réduction fiscale',
        yourEventItemWillCostOnly: 'Votre ticket ne vous coûtera que {0}€ après réduction fiscale',
        yourShopFundingItemWillCostOnly:
          'Votre achat ne vous coûtera que {0}€ après réduction fiscale',
        yourMembershipItemWillCostOnly:
          'Votre adhésion ne vous coûtera que {0}€ après réduction fiscale',
        maxPerUser: 'Limité à {0} par personne',
        remainingCrowdFundingItems:
          'Il ne reste plus de contrepartie disponible | 1 contrepartie restante | {remaining} contreparties restantes',
        remainingEventItems:
          'Il ne reste plus de place disponible | 1 place restante | {remaining} places restantes',
        remainingMembershipItems:
          'Il ne reste plus de place disponible | 1 place restante | {remaining} places restantes',
        remainingShopItems:
          'Il ne reste plus de produit disponible | 1 produit restant | {remaining} produits restants',
        totalRemainingErrorCrowdFundingItems:
          'Il ne reste plus de montant disponible pour la collecte | La collecte est limitée à 1 montant | La collecte est limitée {total} à montants',
        totalRemainingErrorEventItems:
          "Il ne reste plus de place disponible pour l'évènement | L'évènement est limité à 1 place | L'évènement est limité à {total} places",
        totalRemainingErrorMembershipItems:
          "Il ne reste plus de place disponible pour l'adhésion | L'adhésion est limitée à 1 place | L'adhésion est limitée à {total} places",
        totalRemainingErrorShopItems:
          'Il ne reste plus de produit disponible pour la boutique | La boutique est limitée à 1 produit | La boutique est limitée à {total} produits',
        soldout: 'Épuisé',
        tooMuchTickets:
          'Il ne reste plus de place disponible | Il ne reste que 1 place disponible | Il ne reste que {remainingEntries} places disponibles',
        vatRateEvent: 'Inscription soumise à TVA ({0} %)',
        vatRateMembership: 'Inscription soumise à TVA ({0} %)',
        vatRateShop: 'Achat soumis à TVA ({0} %)',
        installment: 'Par échéance',
        monthly: 'Par mois',
        paymentFrequencyTotal: '(Soit {0} € au total)',
        scheduledPaymentTotal: '(Soit {0} € au total)',
        contribution: 'Contribution',
        extraOptionIsSoldOut:
          "Ce tarif n'est pas disponible car au moins une option obligatoire associée est actuellement en rupture de stock.",
        isFull:
          "Il s'agit du dernier tarif disponible car les options obligatoires associées sont épuisées.",
        maxTiersQuantityReachedPerUser:
          'Le nombre maximum de tarifs payables en une fois a été atteint.'
      }
    },
    stepName: {
      donationSelection: 'Dons',
      paymentForm: 'Vente',
      membershipSelection: "Choix de l'adhésion",
      members: 'Adhérents',
      billingInformation: 'Coordonnées',
      summary: 'Récapitulatif',
      participants: 'Participants',
      contributors: 'Contributeurs',
      buyers: 'Acheteurs',
      productsDetails: 'Détails des produits',
      ticketSelection: 'Choix des billets',
      productSelection: 'Choix des produits',
      type: {
        membership: {
          members: 'Adhérents',
          membershipSelection: "Choix de l'adhésion"
        },
        paymentForm: 'Choix du montant'
      }
    },
    validate: 'Valider',
    validateAndPay: 'Payer',
    ticketsAmount: 'Montant des billets :'
  },
  campaignHeader: {
    by: 'par',
    inFavour: 'au profit de',
    displayMore: 'Voir la description complète',
    displayLess: 'Afficher moins'
  },
  card: {
    memo: {
      amount: 'Total :',
      discount: 'Réduction',
      donation: 'Don',
      free: 'Gratuit',
      options: 'Option | Option x1 | Options x{nbOptions}',
      person: 'personne | personnes',
      title: 'Votre commande',
      type: {
        shop: {
          items: 'Produit | Produit x1 | Produits x{nbTickets}'
        },
        crowdfunding: {
          items: 'Don | Don x1 | Dons x{nbTickets}'
        },
        event: {
          items: 'Billet | Billet x1 | Billets x{nbTickets}'
        },
        membership: {
          items: 'Adhésion | Adhésion x1 | Adhésions x{nbTickets}',
        }
      }
    },
    organization: {
      contact: {
        email: 'Email',
        phone: 'Téléphone',
        showContact: 'Afficher les coordonnées',
        title_1: 'Une question ?',
        title_2: "Contactez l'association :"
      },
      eventLink: 'Évènement en ligne',
      map: 'Carte'
    },
    time: {
      days: 'jour | jours',
      daysLeft: 'jour restant | jours restants',
      hours: 'heure | heures',
      hoursLeft: 'heure restante | heures restantes',
      minutes: 'minute | minutes',
      minutesLeft: 'minute restante | minutes restantes',
      ended: 'La collecte est terminée'
    },
    security: {
      text: 'Toutes les informations bancaires pour traiter ce paiement sont totalement sécurisées. Grâce au cryptage SSL de vos données bancaires, vous êtes assurés de la fiabilité de vos transactions sur HelloAsso.',
      title: 'Plateforme de paiement 100% sécurisée'
    },
    seo: 'Sur le même thème',
    socials: {
      title: {
        campaign: 'Partager',
        collect: 'Partager la collecte',
        event: "Partager l'événement",
        page: 'Partager cette page',
        shop: 'Partager la boutique'
      }
    }
  },
  checkout: {
    backLabel: 'Retourner sur le site de {0}',
    footer: {
      conditions: 'CGU',
      confidentiality: 'Confidentialité',
      poweredBy: 'Créé et diffusé avec'
    },
    infos: {
      company: 'Raison sociale',
      email: 'Email',
      firstName: 'Prénom',
      lastName: 'Nom',
      payAsOrg: "Payer en tant qu'organisme",
      title: 'Informations du payeur'
    },
    pay: 'Payer {0} €',
    paymentNumber: 'En {0} fois',
    scheduler: {
      contribution: 'Dont contribution à HelloAsso',
      for: 'Pour {0}',
      date: 'Le {0}',
      showDetails: 'Afficher',
      hideDetails: 'Cacher',
      ofPayments: 'le détail des échéances',
      title: 'Vos échéances de prélèvement',
      today: "Aujourd'hui",
      total: 'Total'
    },
    securedPayment: 'Paiement sécurisé',
    security: {
      text: 'Tous les paiements utilisant la solution HelloAsso sont sécurisés. Les informations bancaires pour traiter les transactions sont protégées grâce au cryptage SSL.',
      title: 'HelloAsso sécurise votre paiement'
    },
    summary: {
      aboutContribution:
        'HelloAsso fonctionne grâce à la générosité de gens comme vous. Votre contribution volontaire de {1} € est notre unique source de revenus et nous permet de fournir gratuitement nos services à davantage d’associations comme {0}.',
      acceptGdpr_1: "J'accepte les",
      acceptGdpr_2: "du service et j'ai lu la",
      acceptGdprLink_1: 'Conditions Générales d’Utilisation',
      acceptGdprLink_2: 'charte de confidentialité',
      contribution: 'Votre contribution au modèle solidaire de HelloAsso',
      eachPayment: 'Appliquée à chaque échéance',
      paymentSolution:
        'En choisissant notre solution solidaire, {0} ne paye aucune commission ou frais bancaires sur ses transactions',
      paymentTo: 'Règlement à {0}',
      title: "À régler aujourd'hui",
      total: 'Total',
      understandContribution:
        "J'ai compris que HelloAsso est une entreprise sociale et solidaire et confirme vouloir lui apporter une contribution financière, facultative et modifiable, en finalisant mon paiement pour assurer la gratuité du service à l'association.",
      updateContribution: 'Modifier la contribution volontaire',
      updateContributionLabel: 'la contribution volontaire'
    },
    title: 'Votre règlement',
    pageTitle: 'Règlement à {0} | Helloasso'
  },
  contributionNotice: {
    text_1:
      "HelloAsso est une entreprise sociale et solidaire, qui fournit gratuitement ses technologies de paiement à l'organisme",
    text_2:
      '. Une contribution au fonctionnement de HelloAsso, modifiable et facultative, vous sera proposée avant la validation de votre paiement.'
  },
  coupon: {
    invalid: 'Code invalide',
    question: 'Avez-vous un code promo ?',
    update: 'Modifier le code promo',
    valid: 'Code valide',
    label: 'Code promo'
  },
  error: {
    cart: {
      ticketsSoldOut: {
        title: 'Trop tard, certains billets ont déjà été achetés.',
        body: 'Merci de faire votre choix parmi les places disponibles restantes.'
      },
      optionsSoldOut: {
        title: 'Trop tard, certaines options ne sont plus disponibles.',
        body: 'Merci de faire votre choix parmi les options disponibles restantes.'
      },
      minimumCart: {
        title: 'Panier minimum non atteint',
        body: 'Veuillez modifier le montant de votre panier. Il doit être gratuit ou supérieur à 0,50€ pour autoriser votre paiement.'
      },
      ticketsHasChanged: {
        title: 'Tarif non reconnu',
        body: 'Un administrateur vient de changer les informations de ce tarif.'
      },
      update: {
        title: 'Une erreur est survenue.',
        body: "Des informations n'ont pas pu être mises à jour"
      }
    },
    code: {
      504: {
        details:
          'Le problème rencontré est temporaire, il est déjà en cours de résolution par nos équipes techniques. Nous vous prions de nous excuser pour la gêne occasionnée.',
        subdetails:
          'Nous vous invitons à réessayer dans quelques instants pour accéder à votre page.',
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      400: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'La requête est invalide.',
        title: 'Toutes nos excuses !'
      },
      401: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'La requête est invalide.',
        title: 'Toutes nos excuses !'
      },
      403: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: "Vous n'avez pas les droits nécessaires pour accéder à cette page.",
        title: 'Toutes nos excuses !'
      },
      404: {
        details:
          "Désolé, la page à laquelle vous voulez accéder ne peut pas être trouvée. Le lien est probablement cassé ou bien la page n'existe plus.",
        message: 'Le lien est cassé ou la page a été supprimée.',
        title: 'Page introuvable'
      },
      408: {
        details: "L'opération semble être en conflit, veuillez ré-essayer ultérieurement.",
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      409: {
        details: "L'opération semble être en conflit, veuillez ré-essayer ultérieurement.",
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      413: {
        details: 'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        message: 'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        title: 'Le fichier est trop volumineux.'
      },
      415: {
        details: 'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        message: 'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        title: "Le fichier n'a pas le bon format"
      },
      421: {
        message: "Nous n'avons pas pu envoyer votre commande vers le serveur",
        title: 'Toutes nos excuses !'
      },
      500: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      uncaught: {
        message: "L'application a rencontré une erreur",
        title: 'Toutes nos excuses !'
      },
      payment: {
        details: "L'opération semble être en conflit, veuillez ré-essayer ultérieurement.",
        message: 'Une erreur est survenue lors du paiement.',
        title: 'Toutes nos excuses !'
      },
      duplicatedPayment: {
        details: 'Paiement en double.',
        message: 'Paiement en double.',
        title: 'Toutes nos excuses !'
      },
      paymentCanceled: {
        details: 'Paiement expiré.',
        message: 'Paiement expiré.',
        title: 'Toutes nos excuses !'
      },
      expiration: {
        message: 'Votre panier a expiré, veuillez ré-essayer ultérieurement.',
        title: 'Toutes nos excuses !'
      },
      ECONNABORTED: {
        message: "La requête est trop longue et n'a pas pu aboutir. Veuillez ré-essayer.",
        title: 'La requête a échoué.'
      }
    },
    form: {
      details:
        'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
      message: 'Il y a des erreurs dans le formulaire.',
      title: 'Attention'
    },
    genericError: 'Une erreur est survenue.',
    page: {
      back: 'Retour',
      backToHome: "Revenir à l'accueil",
      redirection_1: 'Vous pouvez nous contacter',
      redirection_2: 'ici',
      redirection_3: 'au sujet de ce problème.',
      statusCode: "Code d'erreur : {0}"
    },
    payment: {
      canceled: 'Le paiement a été annulé',
      errorCodes: {
        400: 'Le panier est invalide',
        404: 'Le panier est inconnu ou a expiré',
        409: 'Le panier est invalide',
        gdrpConsentError: 'Merci d’accepter les Conditions Générales d’Utilisation du service.',
        cgvConsentError: 'Vous devez accepter les CGV : {0}',
        ORGANIZATION_WALLET_ERROR: "Erreur de validation de l'organisme",
        tipConsentError:
          'Merci de cocher la case pour confirmer votre soutien financier à HelloAsso. Vous pouvez également le modifier ci-dessus.',
        UNKNOWN: 'Une erreur inconnue est survenue'
      }
    },
    unknown: {
      details:
        'Nous avons dû recevoir une alerte grâce à vous, et nous sommes en train de résoudre le problème !',
      message: 'Cette erreur nous est inconnue.',
      title: 'Toutes nos excuses !'
    },
    upload: 'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
    uploadedFileTooLarge: 'Le fichier est trop volumineux.',
    uploadedFileWrongFormat: "Le fichier n'a pas le bon format"
  },
  fiscality: {
    intro:
      'Le don à {0} ouvre droit à une réduction fiscale car il remplit les conditions générales prévues aux articles 200 et 238 bis du code général des impôts.',
    orgSubtitle: 'Organisme / Entreprise',
    title: 'Informations fiscalité',
    userSubtitle: 'Particulier'
  },
  contributors: {
    title: 'Les contributeurs',
    moreButton: 'Plus de contributeurs',
    date: 'Le {0}'
  },
  footer: {
    cta: 'En savoir plus',
    features: {
      alternativeText:
        'Grâce à un modèle économique reposant uniquement sur la contribution volontaire de chacun',
      alternativeTitle: 'Alternatif',
      humanText:
        'Derrière les lignes de codes, il y a toute une équipe engagée auprès de chaque utilisateur.',
      humanTitle: 'Humain',
      universalText: 'Une solution accessible au plus grand nombre, simple à utiliser',
      universalTitle: 'Pour tous'
    },
    link: {
      cguOrg: 'CGU Association',
      cguUser: 'CGU Utilisateur',
      confidentiality: 'Confidentialité',
      cookies: 'Gestion des cookies',
      guide: 'Guide des Associations',
      legalNotice: 'Mentions légales'
    },
    mentions: {
      copyright: 'Tous droits réservés',
      reassurance:
        'La plateforme de paiement des associations pour rechercher des financements : adhésion, dons, crowdfunding, boutique en ligne, billetterie... Lancez-vous !',
      lemonway_1:
        "HelloAsso est un établissement de paiement agréé par l'ACPR en France le 08/06/2023 sous le numéro 17678Q.",
      lemonway_2:
        "Notre plateforme de paiement en ligne est également agréée ESUS (Entreprise Solidaire d'Utilité Sociale), ainsi qu'Intermédiaire en Financement Participatif auprès de l'ORIAS sous le numéro 17002233.",
      lemonway_3:
        "HelloAsso est partenaire de Lemonway, établissement de paiement agréé par l'ACPR en France le 24/12/2012 sous le numéro 16568J.",
      fpfMember: "HelloAsso est membre de l'association Financement Participatif France",
      regulatedPlatform: 'Plateforme régulée par les autorités françaises'
    },
    missions: {
      aboutHa_1:
        "HelloAsso est une entreprise solidaire d'utilité sociale. Nous fournissons nos technologies de paiement",
      aboutHa_2: 'gratuitement, sans frais ni commissions,',
      aboutHa_3: 'à plus de',
      aboutHa_4: '200 000 associations françaises.',
      aboutHa_5:
        'Les contributions volontaires que nous laissent les internautes sont notre unique source de revenus. Merci pour votre soutien !'
    }
  },
  header: {
    accessibilityLabel: "Ouvrir les paramètres d'accessibilité",
    ariaLabel: 'Navigation du site',
    menuCloseLabel: 'Fermer le menu',
    menuOpenLabel: 'Ouvrir le menu',
    organizations: {
      add: 'Ajouter un organisme',
      title: 'Mes organismes'
    },
    settings: {
      accessibility: 'Accessibilité',
      help: 'Aide',
      history: 'Mon historique',
      signout: 'Déconnexion',
      profile: 'Mon profil'
    },
    signin: 'Mon compte',
    signup: 'Inscrire mon association'
  },
  jumbotron: {
    subtitle: 'Pour plus d’informations, rapprochez-vous de l’association organisatrice',
    title: {
      isCrowdfundingEnded: 'La collecte est terminée !',
      isEventEnded: "L'événement est terminé !",
      isMembershipEnded: "La date limite d'adhésion est dépassée !",
      isSaleEventEnded: 'La date limite d’inscription est dépassée !',
      isSaleMembershipEnded: "La date limite d'adhésion est dépassée !",
      isSaleShopEnded: "La date limite d'achat est dépassée !",
      isSaleEventNotYetOpen: 'La vente de billets commence le {0}',
      isSaleMembershipNotYetOpen: "L'ouverture de l'adhésion commence le {0}",
      isSaleShopNotYetOpen: 'La vente commence le {0}',
      isSoldOut: 'Toutes les places disponibles en ligne ont été vendues !'
    },
    toOrganismPage: 'Voir la page',
    toSettings: 'Accéder à mes paramètres'
  },
  loading: {
    subtitle: 'Vous allez être redirigé dans quelques instants...',
    title: 'Merci de patienter'
  },
  metaTags: {
    event: {
      description: {
        descFirst: '{0} - {1} {2} organise 1 {3} à {4}',
        descLast: '{0} {1} organise 1 {2} à {3} - {4}',
        suffix: '{0} - Réservez vite en ligne'
      },
      title: {
        Atelier: 'Atelier',
        Compétition: 'Compétition',
        Cours: 'Cours',
        Concert: 'Concert',
        Festival: 'Festival',
        Fête: 'Fête',
        Spectacle: 'Spectacle',
        Jeu: 'Jeu',
        Conférence: 'Conférence',
        Culte: 'Culte',
        Formation: 'Formation',
        Bénévolat: 'Bénévolat',
        Séjour: 'Séjour',
        Exposition: 'Exposition',
        Vente: 'Vente',
        Sortie: 'Sortie'
      },
      descriptionWithCity: '{0} organisé·e par {1} à {2} - {3}',
      descriptionWithoutCity: '{0} organisé·e par {1} - {2}'
    },
    membership: {
      title: {
        Atelier: 'Atelier',
        Cours: 'Cours',
        'Financement de projet': 'Financement de projet',
        Compétition: 'Compétition',
        Cotisation: 'Cotisation',
        Bénévolat: 'Bénévolat',
        Formation: 'Formation',
        Séjour: 'Séjour'
      },
      descriptionWithCity: '{0} organisé·e par {1} à {2} - {3}',
      descriptionWithoutCity: '{0} organisé·e par {1} - {2}'
    },
    payment: {
      finalization: {
        confirmation: 'Paiement validé',
        pending: 'Paiement en cours'
      },
      regularization: {
        confirmation: 'Régularisation validée',
        failure: 'Échec de la régularisation',
        pending: 'Régularisation en cours'
      }
    },
    redirect: 'Redirection en cours...',
    shop: {
      title: 'Vente',
      descriptionWithCity: '{0} organisée par {1} à {2} - {3}',
      descriptionWithoutCity: '{0} organisée par {1} - {2}',
      descriptionMasculinWithCity: '{0} organisé par {1} à {2} - {3}',
      descriptionMasculinWithoutCity: '{0} organisé par {1} - {2}'
    },
    crowdfunding: {
      title: 'Financement participatif',
      descriptionWithCity: '{0} organisée par {1} à {2} - {3}',
      descriptionWithoutCity: '{0} organisée par {1} - {2}',
      descriptionMasculinWithCity: '{0} organisé par {1} à {2} - {3}',
      descriptionMasculinWithoutCity: '{0} organisé par {1} - {2}'
    },
    donation: {
      description: 'Faire un don à {0} - {1}'
    }
  },
  modal: {
    close: 'Fermer',
    contributionExplication: {
      conclusion: 'Tout cela n’est possible que grâce à votre soutien.',
      content_1:
        'En assurant notre fonctionnement, il permet à plus de 200 000 associations d’avoir accès à une solution de paiement adaptée à leurs besoins, sans frais ni commission.',
      content_2:
        'Il permet de salarier une équipe de plus de 100 personnes dans laquelle chacun accompagne les associations dans le développement de leurs activités.',
      content_3:
        'Il rend possible l’amélioration de nos services d’accompagnement et de nos technologies.',
      cta: "Merci, j'ai compris",
      intro_1: 'C’est pour cette raison que nous avons besoin de vous.',
      intro_2: 'À quoi sert votre soutien à HelloAsso ?',
      title: 'Même le paiement en ligne a un coût'
    },
    contributionSelection: {
      noDonationDescription:
        'Vous pouvez toujours, si vous le souhaitez, nous aider à votre manière, en parlant d’HelloAsso autour de vous ❤️',
      newModal: {
        title: 'Modifier ma contribution',
        catchPhrase: {
          noDonation: "C'est notre seule source de revenus !",
          lowDonation: 'Vous offrez HelloAsso à {0}',
          midDonation: "Vous offrez HelloAsso à des milliers d'associations",
          highDonation: "Vous faites grandir le mouvement de l'engagement"
        },
        textImage: {
          noDonation:
            'Sans votre soutien, nous ne pourrions pas offrir nos services à plus de 300 000 associations. <b>Votre générosité fait toute la différence !</b>',
          lowDonation:
            'Merci ! Votre geste nous permet de rester 100% gratuits pour {0} (pas de frais, ni de commission sur votre paiement).',
          midDonation:
            'Un grand merci ! Vous rejoignez les citoyens engagés qui nous permettent de créer des <b>services essentiels et gratuits pour plus de 300 000 associations.</b>',
          highDonation:
            'Merci de soutenir notre mission ! Grâce à vous, nous avons les moyens d’<b>aider les associations à grandir</b> et les <b>citoyens à s’engager</b> auprès d’elles.'
        },
        contributeUpTo: 'votre soutien :',
        validate: 'Valider',
        catchUpTips: {
          noThankYou: "Je ne souhaite pas soutenir aujourd'hui",
          addTips: 'Je laisse une contribution volontaire de {0} €'
        },
        why: "Mieux comprendre l'impact de ma contribution"
      }
    },
    expiredSession: {
      content_1: "Votre session a expiré pour cause d'inactivité.",
      content_2: {
        crowdfunding:
          "Retournez à la page d'accueil de la campagne pour poursuivre votre paiement.",
        event: "Retournez à la page d'accueil de l'événement pour poursuivre votre inscription.",
        membership: "Retournez à la page d'accueil de la campagne pour poursuivre votre adhésion.",
        shop: "Retournez à la page d'accueil de la boutique pour poursuivre vos achats."
      },
      title: 'Session expirée'
    },
    gdprDetails: {
      content_1:
        'Les traitements informatisés sur ces données sont réalisés par la Société HelloAsso en conformité avec le Règlement Général sur la Protection des Données (RGPD), entré en application le 25 Mai 2018.',
      content_2:
        'Pour plus d’informations concernant le traitement de vos données, nous vous invitons à consulter notre charte de confidentialité :',
      content_2_linkLabel: 'Vers la page Confidentialité',
      content_3:
        'Pour toute demande d’exercice de vos droits, vous pouvez contacter notre DPO par mail à',
      content_4: 'ou lui envoyer un courrier à l’adresse de notre Société.',
      intro:
        'Les données personnelles que vous renseignez dans les formulaires HelloAsso sont collectées afin de mettre en oeuvre et de réaliser les prestations que nous proposons sur notre Plateforme :',
      listContent_1: 'Créer et gérer votre profil utilisateur',
      listContent_2:
        'Créer et gérer votre accès à la Plateforme et aux services accessibles en ligne',
      listContent_3:
        'Adresser des newsletters ou tous messages informatifs relatifs à notre actualité et/ou toute évolution de nos Services',
      listContent_4: 'Élaborer des statistiques d’utilisation et de fréquentation de nos services',
      listContent_5: 'Respecter nos obligations légales et réglementaires',
      title: 'Données personnelles'
    },
    login: {
      connect: 'Se connecter',
      email: 'Email',
      errors: {
        login: {
          details: 'La connexion a été refusée par le serveur.',
          message: 'Veuillez vérifier votre identifiant et votre mot de passe.',
          title: 'Une erreur est survenue.'
        }
      },
      forgotPassword: 'Mot de passe oublié ?',
      password: 'Mot de passe',
      title: 'Connexion à HelloAsso',
      firstTimeConnect: 'Première connexion ?'
    },
    report: {
      emailInput: {
        error: 'Votre email doit suivre le format suivant : "jean@exemple.fr".',
        label: 'Votre email',
        placeholder: 'Exemple : jean@exemple.com'
      },
      errorToast: {
        body: "Votre signalement n'a pas pu être envoyé, veuillez réessayer ultérieurement.",
        title: "Une erreur s'est produite."
      },
      messageInput: {
        error: 'Veuillez renseigner la raison de votre signalement.',
        label: 'Votre message',
        placeholder: 'La raison de votre signalement.'
      },
      reasonRequired: 'Vous devez sélectionner une raison.',
      reasons: {
        DISLIKE: "Je ne l'aime pas",
        INTELLECTUAL_PROPERTY:
          "Je pense que c'est une utilisation non autorisée de ma propriété intellectuelle",
        OFF_TOPIC: "Je pense qu'elle n'a rien à faire sur HelloAsso",
        SPAM: "C'est du contenu indésirable"
      },
      successToast: {
        body: 'Merci pour votre contribution.',
        title: 'Signalement envoyé.'
      },
      title: 'Signaler cette page',
      why: 'Pourquoi souhaitez-vous signaler cette page ?'
    }
  },
  option: {
    required: '(obligatoire)',
    available: 'Épuisé | 1 disponible | {available} disponibles',
    free: 'Gratuit',
    vat: 'TVA {0} %'
  },
  optionalDonation: {
    Shop: {
      addDonation_1: 'Souhaitez-vous ajouter un don à',
      addDonation_2: 'en plus de vos achats ?'
    },
    Event: {
      addDonation_1: 'Souhaitez-vous ajouter un don à',
      addDonation_2: 'en plus de votre inscription ?'
    },
    Membership: {
      addDonation_1: 'Souhaitez-vous faire un don à',
      addDonation_2: 'en plus de votre adhésion ?'
    },
    freeDonationPlaceholder: 'Montant de votre choix',
    noDonation: 'Pas de don',
    chooseDonation: 'Choisir un montant',
    accessibilityRadio: 'Bouton pour choisir',
    accessibilityRadioCustom: 'le montant de votre choix'
  },
  organismType: {
    association: "L'association",
    endowmentFund: 'Le fond de dotation',
    foundation: 'La fondation'
  },
  payment: {
    aboutContribution:
      "Le modèle HelloAsso garantit aux associations d'être reversées de la totalité des paiements comme le vôtre. Nous n'appliquons aucun frais, aucune commission, notre unique source de revenus ne provient pas de la publicité mais de la générosité de gens comme vous qui trouvent notre service utile. Votre soutien est indispensable et permet à notre équipe d'aider encore plus d'associations gratuitement. Merci à celles et ceux qui contribuent à faire grandir un modèle solidaire du web, et merci à ceux que ce message pourrait faire passer à l'action.",
    backToCampaign: 'Retourner sur la campagne',
    backToEvent: 'Retourner sur la billetterie',
    backToForm: 'Retourner sur le formulaire',
    backToShop: 'Retourner sur la boutique',
    cardNumber: 'Numéro de carte',
    clickOnChosenSocialNetwork: 'Cliquez sur l’icône du réseau social choisi',
    confirmationMail: "Vous allez recevoir un mail de confirmation à l'adresse :",
    cvc: 'Cryptogramme visuel',
    cvcDetails: 'Les 3 chiffres se trouvant au dos de votre carte bancaire.',
    dependingOnYourBank:
      'Selon votre établissement bancaire, vous pourrez être redirigé vers la page d’authentification de votre banque avant la validation de votre paiement.',
    downloadDocument: 'Téléchargez vos documents',
    enterCardInfos: 'Veuillez saisir les informations de la carte.',
    error:
      'Rassurez-vous, aucune somme n\'a été débitée et nous vous invitons à renouveler votre paiement. Pour vous aider à finaliser votre paiement, nous vous invitons à <a href="https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement" target="_blank" :title="aide en ligne">consulter notre aide en ligne</a> ou à contacter votre établissement bancaire.',
    errorCode: "Code d'erreur : {0}",
    expirationDate: 'Date d’expiration',
    fiscalReceipt: 'Reçu fiscal',
    getOrders: 'Retrouvez vos commandes à tout moment dans',
    getReceipt: 'Vos documents sont disponibles à tout moment dans',
    getHelp: "Obtenir de l'aide",
    goBack: "Revenir à l'étape précédente",
    invitePeople: 'Invitez vos proches à vous rejoindre :',
    lastStep: 'Une dernière étape pour soutenir {0}, faites passer le message !',
    loading: 'Chargement...',
    madeBy: 'organisé par {0}',
    mandatoryFields: '*champs obligatoires',
    membershipCard: "Carte d'adhérent",
    nextPayment: 'Votre prochaine échéance de {0}€ est prévue pour le {1}.',
    oneOutOfTwo: '1 personne sur 2 participe suite à une recommandation.',
    order: 'Commande n°{0}',
    pay: 'Payer {0}€',
    payTerm: 'Payer mon échéance',
    paymentFailed: "Le prélèvement de votre échéance n'a pas abouti.",
    paymentInfos: 'Informations de paiement',
    paymentRefused: 'Votre demande de paiement a été refusée.',
    paymentSuccess: 'Votre paiement de {0}€ a bien été effectué.',
    pleaseCheckInfos:
      'Vérifiez votre solde ou modifiez votre carte bancaire pour retenter votre prélèvement.',
    pleaseTryAgain:
      'Nous vous invitons à renouveler votre paiement, et à contacter votre établissement bancaire si le problème persiste.',
    paymentReceipt: 'Attestation de paiement',
    regularizationSuccess: 'Votre paiement de {0}€ a bien été régularisé.',
    relanchPayment: 'Retenter le prélèvement',
    sharingIsCaring: "Partager, c'est soutenir : envoyez ce formulaire à vos contacts",
    summary: {
      amount: 'Montant',
      contribution: 'dont contribution à HelloAsso',
      date: 'Prélèvement du',
      outOf: '{0} sur {1}',
      paymentNumber: 'Échéance',
      refused: 'Refusé par la banque',
      status: 'Statut',
      title: 'Récapitulatif :'
    },
    thanks: 'Message de {0} :',
    ticket: 'Billet',
    title: {
      confirmation: 'Confirmation du paiement',
      pending: 'Paiement',
      regularizationCancellation: 'Annulation de la régularisation de votre échéance',
      regularizationConfirmation: 'Confirmation de la régularisation de votre échéance',
      regularizationFailure: 'Échec de la régularisation de votre échéance',
      regularizationPending: 'Régularisation de votre échéance du {0}'
    },
    toHelpCenter: "Vers le Centre d'aide",
    totalAmountContribution: 'dont contribution à HelloAsso',
    totalAmountToPay: 'Montant total à payer',
    updateTickets: 'Modifier mon billet',
    useNewPaymentMethod:
      'En cas de modification, enregistrer ma nouvelle carte pour payer les prochaines échéances.',
    youCanBeProud: 'Invitez vos proches à vous rejoindre :',
    youCanHelp: 'Vous pouvez aider {0} en partageant son initiative {1}.',
    youCanTryAgain:
      'Si vous le désirez, vous pouvez renouveler votre tentative de paiement. En cas de problème, merci de contacter votre établissement bancaire.',
    yourPaymentHasBeenRefused: "Votre tentative de paiement n'a pas été validée.",
    paymentIntentAuthenticationFailure: {
      title: 'Votre paiement n’a pas pu être finalisé.',
      content:
        'Cet échec de paiement est en lien avec la saisie de vos coordonnées de carte bancaire, de votre cryptogramme ou votre code 3D Secure.\r\nPour vous aider à finaliser votre paiement, nous vous invitons à <a href="https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement" target="_blank" :title="aide en ligne">consulter notre aide en ligne</a>.\r\nRassurez-vous, aucune somme n’a été débité suite à votre tentative de paiement.'
    },
    validatedPayment: {
      title: 'Votre paiement est validé.',
      content:
        'Vous recevrez votre confirmation de paiement et vos documents par email dans un délai de 15 à 60 minutes.\r\nSi vous ne recevez pas de confirmation par email, nous vous invitons à <a href="https://www.helloasso.com/contactez-nous" target="_blank" :title="">nous contacter</a>.'
    },
    yourUserspace: 'votre espace utilisateur.',
    cardUpdate:
      "Le changement de carte s'appliquera sur l'ensemble des échéances à venir pour cette commande.",
    security: 'Plateforme de paiement 100% sécurisée',
    unpaid: {
      paymentDetail: {
        summary: 'Récapitulatif',
        commandNumber: 'Commande n°',
        paymentDate: 'Prélèvement du',
        installment: 'Echéance',
        amount: 'Montant',
        tip: 'dont contribution à HelloAsso',
        status: 'Statut',
        totalAmount: 'Montant total à payer',
        on: 'sur',
        refused: 'Refusé',
        showMoreDetail: 'Afficher plus de détail'
      }
    },
    apiHaPay: {
      unknownError: 'hapay_unknow_error',
      dbError: 'hapay_db_error',
      providerError: 'hapay_provider_error',
      authenticationError: 'hapay_authentication_error'
    },
    modal: {
      waitingRedirectTitle: "Nous finalisons votre paiement, cela peut prendre jusqu'à 1 minute.",
      waitingRedirectContent: 'Merci de ne pas rafraîchir la page.'
    },
    thanksSupport: {
      supporting: {
        title: 'Merci de soutenir HelloAsso dans sa mission ❤️',
        paragraph:
          'Grâce à votre contribution volontaire, nous ne payerons aucune commission sur votre paiement et profitons gratuitement des outils de paiement en ligne de HelloAsso. Vos soutiens sont leur unique source de revenus, merci pour eux.'
      },
      notSupporting: {
        title: 'Votre soutien à notre association n’a pas de prix, mais il a un coût 💳',
        paragraph:
          'Sur une autre plateforme, nous devrions payer des frais sur votre paiement et ne pourrions pas obtenir l\'intégralité de notre collecte. <br class="hideDesktop" /><br class="hideDesktop"/><strong>En choisissant HelloAsso, nous bénéficions d’un service gratuit qui nous permet de vous proposer le paiement en ligne.</strong><br /><br />Tout cela est possible uniquement grâce aux contributions volontaires que vous pouvez laisser pour soutenir HelloAsso dans sa mission. Sans elles, <strong>42%</strong> des associations comme la nôtre renonceraient au paiement en ligne faute de moyens.'
      }
    },
    HASocials:
      'Suivez HelloAsso sur Instagram pour faire le plein de projets à impact, de sport, de culture, de nature.. 👇'
  },
  share: {
    buttonLabel: {
      copyUrl: "Copier l'URL",
      email: {
        campaign: 'Partager la campagne par email',
        collect: 'Partager la collecte par email',
        event: "Partager l'événement par email",
        page: 'Partager cette page par email',
        shop: 'Partager la boutique par email',
        name: 'Email'
      },
      facebook: {
        campaign: 'Partager la campagne sur Facebook',
        collect: 'Partager la collecte sur Facebook',
        event: "Partager l'événement sur Facebook",
        page: 'Partager cette page sur Facebook',
        shop: 'Partager la boutique sur Facebook',
        name: 'Facebook'
      },
      linkedin: {
        campaign: 'Partager la campagne sur LinkedIn',
        collect: 'Partager la collecte sur LinkedIn',
        event: "Partager l'événement sur LinkedIn",
        page: 'Partager cette page sur LinkedIn',
        shop: 'Partager la boutique sur LinkedIn',
        name: 'LinkedIn'
      },
      messenger: {
        campaign: 'Partager la campagne sur Messenger',
        collect: 'Partager la collecte sur Messenger',
        event: "Partager l'événement sur Messenger",
        page: 'Partager cette page sur Messenger',
        shop: 'Partager la boutique sur Messenger',
        name: 'Messenger'
      },
      twitter: {
        campaign: 'Partager la campagne sur X',
        collect: 'Partager la collecte sur X',
        event: "Partager l'événement sur X",
        page: 'Partager cette page sur X',
        shop: 'Partager la boutique sur X',
        name: 'ex Twitter'
      },
      genericShare: {
        name: 'Autres applications'
      },
      whatsapp: {
        name: 'WhatsApp',
        campaign: 'Partager la campagne sur WhatsApp',
        collect: 'Partager la collecte sur WhatsApp',
        event: "Partager l'événement sur WhatsApp",
        page: 'Partager cette page sur WhatsApp',
        shop: 'Partager la boutique sur WhatsApp'
      }
    },
    prefilledText: {
      email: {
        checkout:
          '🗓️ Finalisez dès maintenant votre règlement via notre formulaire dédié {0} sur HelloAsso',
        crowdfunding: '🗓️ Participez dès maintenant à notre campagne {0} sur HelloAsso',
        donation: '🗓️ Donnez dès maintenant via notre formulaire de don {0} sur HelloAsso',
        event: '🗓️ Inscrivez-vous dès maintenant pour l’événement {0} sur HelloAsso',
        membership: '🗓️ Adhérez dès maintenant via notre campagne {0} sur HelloAsso',
        paymentform: '🗓️ Payez dès maintenant via notre formulaire dédié {0} sur HelloAsso',
        shop: '🗓️ Achetez dès maintenant via notre boutique {0} sur HelloAsso'
      },
      twitter: {
        checkout:
          '🗓️ Finalisez dès maintenant votre règlement via notre formulaire dédié {0} : {1} @helloasso',
        crowdfunding: '🗓️ Participez dès maintenant à notre campagne {0} : {1} @helloasso',
        donation: '🗓️ Donnez dès maintenant via notre formulaire de don {0} : {1} @helloasso',
        event: '🗓️ Inscrivez-vous dès maintenant pour l’événement {0} : {1} @helloasso',
        membership: '🗓️ Adhérez dès maintenant via notre campagne {0} : {1} @helloasso',
        paymentform: '🗓️ Payez dès maintenant via notre formulaire dédié {0} : {1} @helloasso',
        shop: '🗓️ Achetez dès maintenant sur notre boutique {0} : {1} @helloasso'
      },
      whatsapp: {
        checkout: '🗓️ Finalisez dès maintenant votre règlement via notre formulaire dédié {0}: {1}',
        crowdfunding: '🗓️ Participez dès maintenant à notre campagne {0}: {1}',
        donation: '🗓️ Donnez dès maintenant via notre formulaire de don {0}: {1}',
        event: '🗓️ Inscrivez-vous dès maintenant pour l’événement {0}: {1}',
        membership: '🗓️ Adhérez dès maintenant via notre campagne {0}: {1}',
        paymentform: '🗓️ Payez dès maintenant via notre formulaire dédié {0}: {1}',
        shop: '🗓️ Achetez dès maintenant sur notre boutique {0}: {1}'
      }
    }
  },
  toast: {
    copyUrl: {
      title: 'URL copiée ! 🎉',
      body: "Vous pouvez maintenant l'utiliser pour partager votre campagne."
    },
    failedRedirection: {
      title: 'Arf ! 😕',
      body: 'La redirection vers le paiement a échoué. Merci de réessayer.'
    },
    usedToken: {
      title: 'Désolé ! 😧',
      body: "Le lien auquel vous souhaitez accéder n'est plus valide ou a déjà été utilisé."
    },
    prospect: {
      title: 'Email sauvegardé',
      body: "L'association {0} reviendra vite vers vous."
    }
  },
  tooltip: {
    emailConfirmation:
      'Votre adresse email va nous permettre de vous envoyer votre email de confirmation, ainsi que vos justificatifs.'
  },
  total: {
    monthlyDonation: 'Montant du paiement chaque mois',
    amount: 'Montant à payer :',
    amountTTC: 'Montant TTC :',
    amountWithVAT: 'Dont TVA :',
    toBePaidToday: "À régler aujourd'hui :",
    noCV: 'hors contribution volontaire*',
    installments: 'Paiement en 1x, 3x, 6x, 10x ou 12x',
    type: {
      shop: {
        amountTickets: 'Montant des achats :',
        amountTicketsModalities: 'Montant TTC :',
        subtitle: 'à payer pour vos achats',
        today: "à payer aujourd'hui",
        installmentText1: 'puis {0} € les prochaines fois',
        installmentText2: 'Soit {0} € reversés à l’association {1}',
        installmentText3: 'et',
        installmentText4: '€ d’aide au fonctionnement pour HelloAsso'
      },
      event: {
        amountTickets: 'Montant des billets :'
      },
      membership: {
        amountTickets: "Montant à payer pour la durée de l'adhésion :"
      },
      crowdfunding: {
        amountTickets: 'Montant à payer :'
      }
    }
  },
  validators: {
    abnormalEmail:
      'Votre adresse email semble contenir des caractères non conformes. Veillez à la vérifier avant de valider votre paiement.',
    legalAge: 'Les mineurs ne sont pas autorisés à effectuer ce genre de paiement.',
    youMustChooseAStatus: 'Vous devez choisir une forme juridique dans la liste',
    optionIsMandatory: 'Cette option est obligatoire',
    date: "Cette date n'est pas valide",
    email: 'Votre adresse email est invalide',
    emailMatch: "L'adresse email ne correspond pas à l'adresse saisie dans le champ précédent.",
    notPositive: 'La valeur doit être positive.',
    maxAmount: 'La valeur doit être un nombre inférieur à {0}€',
    minAmount: 'La valeur doit être un nombre supérieur à {0}€',
    nameChars:
      "Votre {0} ne peut pas contenir l'un des caractères suivants  :  , . \\ / * ² & # @ ^ ¨ _ -- + * > <",
    nameConsecutiveChars: 'Votre {0} ne doit pas contenir 3 caractères identiques consécutifs.',
    nameContainsForbidden:
      'Votre {0} ne peut pas contenir firstname, lastname, unknown, first_name, last_name ou anonyme.',
    nameDifferent: 'Vos noms et prénoms doivent être différents.',
    nameIsForbidden: 'Votre {0} est invalide.',
    nameLatin: "Votre {0} doit être écrit en utilisant l'alphabet latin.",
    nameLength: 'Votre {0} ne doit pas contenir un seul caractère.',
    nameNoNumbers: 'Votre {0} ne doit pas contenir de chiffre.',
    nameVowels: 'Votre {0} doit contenir au moins une voyelle.',
    password: 'Votre mot de passe est trop simple.',
    passwordMatch: 'Les mots de passe ne sont pas identiques.',
    phone: 'Ce numéro de téléphone est invalide',
    required: 'Tous les champs obligatoires doivent être renseignés',
    requiredAmount: 'Merci de saisir un montant',
    zipCode: 'Le code postal est incorrect.',
    siren: 'Votre code SIREN doit être composé de 9 chiffres.',
    maxLength: 'Le champ est limité à {0} caractères maximum',
    birthdate: "La date de naissance n'est pas valide."
  },
  widget: {
    form: {
      crowdfunding: 'Crowdfunding',
      event: 'Billeterie',
      membership: 'Adhésion',
      shop: 'Boutique',
      paymentform: 'Vente',
      donation: 'Donation'
    },
    button: {
      widget: 'Participer',
      crowdfunding: 'Faire un don',
      event: 'Participer',
      membership: 'Adhérer',
      shop: 'Acheter',
      paymentform: 'Faire un paiement',
      donation: 'Faire un don'
    },
    vignette: {
      participate: 'Je participe',
      contribute: 'Je contribue'
    },
    poweredBy: 'Créé et diffusé avec',
    updateCookies: 'Gestion des cookies',
    currencyUnit: '{0}€',
    seeMore: 'Voir plus'
  },
  cartModalities: {
    title: 'Facilités de paiement',
    radio: {
      once: 'Payer la totalité maintenant',
      multiple: 'Payer en plusieurs fois'
    },
    chooseSuitableModalities: 'Choisir les modalités de paiement adaptées à vos besoins',
    schedule: 'Échéancier',
    today: "Aujourd'hui",
    details: '(Voir détails)',
    modal: {
      header: 'Informations sur votre premier paiement',
      disclaimer: 'Les éléments suivants doivent être réglés immédiatement.',
      options: 'Vos options',
      donations: 'Vos dons',
      donationTo: 'Don à',
      ineligibleTiers: 'Vos tarifs',
      eligibleTiers: 'Première échéance des tarifs éligibles'
    },
    totalCart: 'Total achat'
  },
  abTasty: {
    total: {
      excludingCV: 'Hors contribution à HelloAsso*'
    },
    contributionNotice:
      '<b>*Une contribution</b> c’est une aide à HelloAsso (entreprise sociale et solidaire) pour <b>offrir des services</b> essentiels et gratuits à ',
    topBlockCV: {
      title: 'Pourquoi soutenir HelloAsso ?',
      text1: 'Grâce à votre soutien, nous restons 100% gratuit pour ',
      text2:
        "et des milliers d'autres associations. Participez à la réussite de notre mission en laissant une contribution au moment de votre paiement."
    },
    toggleMore: 'Afficher plus',
    toggleLess: 'Afficher moins',
    headerCV: {
      label: 'Un mouvement 100% financé par les citoyens  ❤️',
      title: 'Soutenir la mission HelloAsso',
      text1: 'Grâce à votre soutien, nous restons 100% gratuit pour ',
      text2:
        " et des milliers d'autres associations. </br> Participez à la réussite de notre mission d'utilité sociale en laissant une contribution au moment de votre paiement. C'est notre seule source de revenus.",
      buttonLabel: "J'ai compris"
    },
    contributionExplanation: {
      title: 'Soutenir notre mission',
      text1:
        "<b>HelloAsso</b> est une entreprise sociale et solidaire qui dépend uniquement des contributions volontaires de personnes comme vous. C'est notre unique source de revenus. <br/> En choisissant de contribuer à notre fonctionnement, vous nous permettez de rendre nos services gratuits pour ",
      text2: 'et plus de 300 000 associations françaises.',
      buttonLabel: "J'ai compris"
    },
    summaryPrice: 'Total de la commande',
    stepFour: {
      discountLabel: 'Ajouter un code promo',
      variationD: {
        cvSelectionTitle: 'Votre contribution à HelloAsso',
        cvSelectionSubtitle: '(libre et modifiable)',
        linkLabel: "Mieux comprendre l'impact de ma contribution",
        cvSelectOption: '{0} €',
        cvOtherAmount: 'Autre montant',
        amountInputLabel: 'Saisir un montant libre',
        noDonationText:
          'Sans votre soutien, nous ne pourrions pas offrir nos services à plus de 300 000 associations. <b>Votre générosité fait toute la différence !</b> 😊',
        catchUpButtonLabel: 'Je laisse une contribution volontaire de {0} €'
      }
    }
  },
  reassurance: {
    securePayment: 'Paiement sécurisé'
  },
  voluntaryCard: {
    title: 'Pourquoi soutenir HelloAsso ?',
    text1: 'Grâce à votre soutien, nous restons 100% gratuit pour ',
    text2:
      "et des milliers d'autres associations. Participez à la réussite de notre mission en laissant une contribution au moment de votre paiement."
  }
}
